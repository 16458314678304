// import * as bootstrap from "bootstrap";
import Swal from "sweetalert2";
import Select2 from "select2";

var Toast = Swal.mixin({
    toast: true,
    position: 'bottom-end',
    showConfirmButton: false,
    timer: 3000
});



document.addEventListener("turbo:frame-load", function (e) {
    ToastFlashMessages();
});

document.addEventListener("turbo:load", function () {

    // ajout bouton pour ajouter une nouvelle fonction depuis le formulaire pfo
    if ($('.ajax-add-new-trainingOrganization').length) {
        var ajax_add_new_url = $('.ajax-add-new-trainingOrganization').attr('data-ajax_add_new_url');

        var dom = '&nbsp;<a href="javascript:void(0)" id="add-new-trainingOrganization-button"><i class="fa fa-plus-circle fa-lg" aria-hidden="true"></i></a>';
        $('.ajax-add-new-trainingOrganization').closest('form').after('<div id="add-new-trainingOrganization-div"></div>');

        $('label[for="' + $('.ajax-add-new-trainingOrganization').attr('id') + '"]').append(dom);
        $("#add-new-trainingOrganization-button").on("click", function (event) {
            if (!event) {
                var event = window.event;
            }

            loadAjaxModalContent("appModal", ajax_add_new_url);
        });
    }


    const defaultModal = new bootstrap.Modal(document.getElementById("appModal"), {});
    initSelect2();

    $('.modal').on('shown.bs.modal', function (e) {
        initializeModalFunctions(e.target.id);
    });

    ToastFlashMessages();

    $(document).on('click', '#sf_admin_list_batch_checkbox', function (e) {
        //console.log($('.tmpclass'));
        var boxes = document.getElementsByTagName('input');
        var checked = $(this).is(':checked');
        for (var index = 0; index < boxes.length; index++) {
            var box = boxes[index];
            if (box.type == 'checkbox' && box.className == 'sf_admin_batch_checkbox') {
                box.checked = checked;
            }
        }
        return true;
    });

    // show/hide div block when click on button wich have class showHideButton
    // need data-target attribute with the id of the dic that will be show/hide
    $('.showHideButton').on('click', function (event) {
        $('#' + $(this).data("target")).toggle();
    });

    $('#batchFormSubmitButton').on('click', function (event) {
        event.preventDefault();
        if ($('#batch_action').val()) {
            if (confirm('Etes vous certain de vouloir réaliser cette action en masse ?')) {
                $('form').submit();
            }
        }
    });

    $("a.confirmModal").click(function (e) {
        var url = $(this).attr('href');
        //$('#appModal').modal('show');
        defaultModal.show();
        e.preventDefault();
        $('#appModal .modal-body').text(($(this).data('dialog-content')));
        $('#appModal .modal-title').text(($(this).data('dialog-title')));
        $('#appModal .btn-validate').text(($(this).data('btn-validate-text')));
        $('#appModal .btn-validate').on('click', function (e) {
            document.location.href = url;
        });
    });

    $(document).on('submit', 'form.confirm-submit', function (e) {
        //console.log(e);
        if (e.originalEvent) {
            e.preventDefault();

            let form = $(this);
            var url = $(this).attr('href');
            $('#appModal').modal('show');
            // e.preventDefault();
            $('#appModal .modal-body').text(Translator.trans('Messages.modal.confirmDeleteItem'));
            $('#appModal .modal-title').text(Translator.trans('Messages.modal.confirmationTitle'));
            $('#appModal .btn-validate').on('click', function () {
                // console.log($(this));
                // return true;
                $(form).trigger('submit');
            });
        }

    });

    //
    $(document).on('change', 'input[type="file"]', function (e) {
        //get the file name
        var fileName = $(this).val();
        $(this).next('.custom-file-label').html(fileName);
    });

    // click on +/- button on stats page  
    $(document).on('click', '.plus-minus-actions', function (e) {
        e.preventDefault();
        var chartContainer = $('#' + $(this).data('chart-container'));
        if (chartContainer.hasClass('col-xxl-6')) {
            chartContainer.removeClass('col-xxl-6');
            chartContainer.addClass('col-xxl-12');
        } else {
            chartContainer.removeClass('col-xxl-12');
            chartContainer.addClass('col-xxl-6');
        }
    });

    $(document).on('click', '.ajax_content', function (e) {
        e.preventDefault();
        e.stopImmediatePropagation();

        var content_url;

        if ($(this).attr('data-url-content')) {
            content_url = $(this).attr('data-url-content')
        } else {
            content_url = $(this).attr('href')
        }

        var modalId = $(this).data('modal-id');
        if (!modalId || !$('#' + modalId).length > 0)
        {
            modalId = 'appModal';
        }

        loadAjaxModalContent(modalId, content_url);
        initializeModalFunctions(modalId);
    });

    $(function () {
        $('body').on('submit', 'form.ajax-form', function (e) {
            e.preventDefault();

            // return;
            var form = $(this);
            var url = $(this).attr('action');
            var type = $(this).attr('method').toUpperCase();
            var target = $(e.target);
            var successAction = $(this).data('success_action');
            var modal = new bootstrap.Modal(document.getElementById('appModal'), {});
            ;
            if (target.closest('.modal').length) {
                var modal = target.closest('.modal');
            }

            if ($(this).next('.loading').length > 0) {
                $(this).next('.loading').removeClass('d-none');
                $(this).hide();
            }

            var formData = new FormData($(this)[0]);
            $.ajax({
                url: url,
                type: type,
                modal: modal,
                data: formData,
                processData: false,
                contentType: false,
                beforeSend: function (e) {

                },
                success: function (data) {
                    data = $.parseJSON(data);
                    if (typeof data.status !== "undefined") {
                        if (data.status === "success") {

                            if (typeof data.message !== "undefined") {
                                var message = data.message;
                            } else {
                                var message = Translator.trans('Generics.flash.editSuccess');
                            }

                            if (modal) {
                                modal.hide();
                                flashMessage(data.status, message);
                                // traitement du retour
                                switch (successAction) {
                                    case 'reload':
                                        window.location.reload();
                                        break;
                                    case 'updateDiv':
                                        break;
                                    default:
                                        return;
                                }

                            } else {
                                flashMessage(data.status, message, true);
                            }

                            // traitement du retour
                            switch (successAction) {
                                case 'reload':
                                    window.location.reload();
                                    break;
                                case 'updateDiv':
                                    break;
                            }

                        } else if (data.status === "warning") {

                            populateAndOpenDefaultModal(data.status, '<div class="alert alert-warning" role="alert">' + JSON.stringify(data.message) + '</div>');

                        } else if (data.status === "replaceModalContent") {

                            replaceContentOfDefaultModal(data.message);


                        } else {
                            // affichage message erreur dans la modal default

                            populateAndOpenDefaultModal(data.status, '<div class="alert alert-danger" role="alert">' + JSON.stringify(data.message) + '</div>');

                            form.find('.modal-body').first().prepend('<div class="alert alert-' + data.status + ' alert-dismissible fade in" role="alert">'
                                    + '<button type="button" class="close" data-dismiss="alert" aria-label="Close"><span aria-hidden="true">×</span></button>'
                                    + data.message + '</div>');
                            hideFlashMessage();

                        }
                    }
                },
                error: function () {
                    Toast.fire({
                        icon: 'error',
                        title: Translator.trans('Messages.flash.genericFailure')
                    });
                }

            });
        });
    });

});

function initSelect2() {
    const listSelect2 = document.querySelectorAll('.select2');
    listSelect2.forEach(item => {
        const parentModal = item.closest("#appModal");
        let select2Options = {theme: "bootstrap-5"};
        if (parentModal) {
            select2Options = {
                dropdownParent: $('#appModal'),
                theme: "bootstrap-5"
            }
        }

        new Select2(item);
        $(item).select2(select2Options);

        $(item).on('select2:select', function () {
            let event = new Event('change', {bubbles: true})
            this.dispatchEvent(event);
        });
    })
}

function ToastFlashMessages() {
    $('.flash-message').each(async function () {
        await Toast.fire({
            icon: $(this).data("label"),
            title: $(this).data("message")
        });
    });
//    const listMessages = document.querySelectorAll('.flash-message')
//    console.log(listMessages);
//    listMessages.forEach(item => {
//        console.log(item);
//    })
}


/**
 * loadAjaxModalContent
 * @param {type} id_modal
 * @param {type} content_url
 * @returns {undefined}
 */
function loadAjaxModalContent(id_modal, content_url) {
    var modal = new bootstrap.Modal(document.getElementById(id_modal), {});
    $('#' + id_modal).load(content_url, function () {
        modal.show();
        initSelect2();
    });
}

function initializeRichTextFields() {
    $('.summernote').summernote({
        height: 300, // set editor height
        minHeight: null, // set minimum height of editor
        maxHeight: null, // set maximum height of editor
        focus: true                  // set focus to editable area after initializing summernote
    });
}

/**
 * initializeModalFunctions
 * @param {type} modalId
 * @returns {undefined}
 */
function initializeModalFunctions(modalId) {
    //initializeModalSelect2(modalId);
    //initializeRichTextFields();
}

/**
 * initializeModalSelect2
 * @param {type} modalId
 * @returns {undefined}
 */
function initializeModalSelect2(modalId) {
    $('.modal select2').css('width', '100%');
    $('.modal select.select2').select2({
        language: "fr",
        dropdownParent: $('#' + modalId),
        theme: 'bootstrap4'
    });
}

/**
 * populate the default modal and open it
 * @param {type} title
 * @param {type} body
 * @returns {undefined}
 */
function populateAndOpenDefaultModal(title, body) {
    $('#appModal').find('.modal-content').first().html(
            '<div class="modal-header">'
            + '<h5 class="modal-title">' + title + '</h5>'
            + '<button type="button" class="close" data-dismiss="modal" aria-label="Close">'
            + '<span aria-hidden="true">&times;</span>'
            + '</button>'
            + '</div>'
            + '<div class="modal-body">' + body + '</div>'
            + '</div>'
            );
    defaultModal.show();
}

function replaceContentOfDefaultModal(body) {
    $('#appModal').html(body);

    defaultModal.show();
    initializeModalFunctions('appModal');
}

/**
 * Show a div to display information .Autohide is default
 * @param {type} status
 * @param {type} message
 * @param {type} autoHide
 * @returns {undefined}
 */
function flashMessage(status, message, autoHide = true)
{
    var curFlashMessageStatus = 'success';
    if ($('#flash-message-container').length > 0)
    {
        $('#flash-message-container').removeClass("alert-" + curFlashMessageStatus);
        $('#flash-message-container').addClass("alert-" + status);
        $('#flashmessagecontent').text(message);
        $('#flash-message-container').show();
    } else {

        $(".custom-search-form").prepend('<div id="flash-message-container" class="ml-auto mb-0 mt-0 alert alert-' + status + ' alert-dismissible " role="alert">'
                + '<span id="flashmessagecontent">' + message + '</span>'
                + '<button type="button" class="close" data-dismiss="alert" aria-label="Close"><span aria-hidden="true">×</span></button>'
                + '</div>');

        $('#flash-message-container').show();
    }
    curFlashMessageStatus = status;
    if (autoHide) {
        setTimeout('$("#flash-message-container").fadeOut(1500)', 1000);
}
}

/**
 * hideFlashMessage
 * @returns {undefined}
 */
function hideFlashMessage() {
    $('#flash-message-container').hide();
}

// replace class oldClass with class newClass on #id element
function replaceClass(id, oldClass, newClass) {
    var elem = $(`#${id}`);
    if (elem.hasClass(oldClass)) {
        elem.removeClass(oldClass);
    }
    elem.addClass(newClass);
}




